export const STATUS = {
  confirming: "confirming",
  created: "created",
  draft: "draft",
  ordered: "ordered",
  verifying: "verifying",
  verified: "verified",
  producing: "producing",
  processing: "processing",
  running: "running",
  pending: "pending",
  cancelled: "cancelled",
  completed: "completed",
}

export const CAMPAIGN_STATUS = {
  DRAFT: "draft",
  WAITING: "waiting",
  CONFIRMED: "confirmed",
  ORDERED: "ordered",
  CONTENT_VERIFYING: "content_verifying",
  CONTENT_REJECTED: "content_rejected",
  CONTENT_VERIFIED: "content_verified",
  PRODUCING: "producing",
  TO_INSPECT: "to_inspect",
  INSPECTED_AND_ACCEPTED: "inspected_and_accepted",
  INSPECTED_AND_REJECTED: "inspected_and_rejected",
  REPRODUCING: "reproducing",
  PENDING: "pending",
  CANCELLED: "cancelled",
  RUNNING: "running",
  COMPLETED: "completed",
}

export const CAMPAIGN_STATUS_TITLE = {
  [CAMPAIGN_STATUS.DRAFT]: "Draft",
  [CAMPAIGN_STATUS.WAITING]: "Waiting",
  [CAMPAIGN_STATUS.ORDERED]: "Ordered",
  [CAMPAIGN_STATUS.CONTENT_VERIFYING]: "Ads Verifying",
  [CAMPAIGN_STATUS.CONTENT_REJECTED]: "Ads Rejected",
  [CAMPAIGN_STATUS.CONTENT_VERIFIED]: "Ads Verified",
  [CAMPAIGN_STATUS.PRODUCING]: "Producing",
  [CAMPAIGN_STATUS.TO_INSPECT]: "Acceptable",
  [CAMPAIGN_STATUS.INSPECTED_AND_ACCEPTED]: "Accepted",
  [CAMPAIGN_STATUS.INSPECTED_AND_REJECTED]: "Rejected",
  [CAMPAIGN_STATUS.REPRODUCING]: "Fixing",
  [CAMPAIGN_STATUS.PENDING]: "Pending",
  [CAMPAIGN_STATUS.CANCELLED]: "Cancelled",
  [CAMPAIGN_STATUS.RUNNING]: "Running",
  [CAMPAIGN_STATUS.COMPLETED]: "Completed",
}
