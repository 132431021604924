import dayjs from "dayjs"
import { REGION_DATA } from "constants/Regions"
import { CAMPAIGN_STATUS, STATUS } from "constants/Status"

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text)
  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea")
    textarea.textContent = text
    textarea.style.position = "fixed" // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand("copy") // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex)
      return prompt("Copy to clipboard: Ctrl+C, Enter", text)
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export const toCapitalize = (string = "") => {
  const words = string?.split(" ")

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1)
  }

  return words.join(" ")
}

export const toCapitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatDate = (date, format = "DD MMM, YYYY") => {
  return dayjs(date).format(format)
}

export const generateCode = (prefix) => {
  let now = Date.now().toString()
  now += now + Math.floor(Math.random() * 10)
  return `${prefix ? `#${prefix}.` : ""}` + now.slice(now.length - 6, now.length)
}

export const getProvinceList = () => {
  return REGION_DATA
}

export const provinceOptions = () => {
  return getProvinceList().map((province) => ({
    value: province.name,
    label: province.name,
  }))
}

export const getStatus = (status) => {
  let statusObj = {}
  switch (status.value) {
    case STATUS.draft:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-orange",
        icon: "path",
      }
      break
    case STATUS.ordered:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-yellow",
        icon: "empty-wallet",
      }
      break
    case STATUS.verifying:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-blue",
        icon: "eye",
      }
      break
    case STATUS.verified:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-darkPurple",
        icon: "verify",
      }
      break
    case STATUS.producing:
    case STATUS.processing:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-purple",
        icon: "story",
      }
      break
    case STATUS.running:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-green",
        icon: "forward",
      }
      break
    case STATUS.pending:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-gray",
        icon: "pause",
      }
      break
    case STATUS.cancelled:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-pink",
        icon: "forbidden",
      }
      break
    case STATUS.completed:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-blue",
        icon: "tickCircle",
      }
      break
    case STATUS.created:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-gray",
        icon: "tickCircle",
      }
      break
    case STATUS.confirming:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-gray",
        icon: "tickCircle",
      }
      break
    default:
      break
  }
  return statusObj
}

export const getCampaignStatus = (status) => {
  let statusObj = {}
  switch (status.value) {
    case CAMPAIGN_STATUS.DRAFT:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-orange",
      }
      break
    case CAMPAIGN_STATUS.WAITING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-yellow",
      }
      break
    case CAMPAIGN_STATUS.ORDERED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-brown",
      }
      break
    case CAMPAIGN_STATUS.CONFIRMED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-brown",
      }
      break
    case CAMPAIGN_STATUS.CONTENT_VERIFYING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-blue2",
      }
      break
    case CAMPAIGN_STATUS.CONTENT_REJECTED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-red2",
      }
      break
    case CAMPAIGN_STATUS.CONTENT_VERIFIED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-darkPurple",
      }
      break
    case CAMPAIGN_STATUS.PRODUCING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-purple",
      }
      break
    case CAMPAIGN_STATUS.TO_INSPECT:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-gray3",
      }
      break
    case CAMPAIGN_STATUS.INSPECTED_AND_ACCEPTED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-primary",
      }
      break
    case CAMPAIGN_STATUS.INSPECTED_AND_REJECTED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-orange2",
      }
      break
    case CAMPAIGN_STATUS.REPRODUCING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-orange3",
      }
      break
    case CAMPAIGN_STATUS.PENDING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-gray4",
      }
      break
    case CAMPAIGN_STATUS.CANCELLED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-red",
      }
      break
    case CAMPAIGN_STATUS.RUNNING:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-green",
      }
      break
    case CAMPAIGN_STATUS.COMPLETED:
      statusObj = {
        key: status.value,
        backgroundColor: "bg-lightBlue",
      }
      break

    default:
      break
  }
  return statusObj
}

export const formatVideoDuration = (time) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time - hours * 3600) / 60)
  const seconds = Math.ceil(time - hours * 3600 - minutes * 60)
  return `${hours > 0 ? `${hours}:` : ''}${minutes || '00'}:${seconds}`
}
