import axios from "../axios"

export const getCampaignList = (params) => {
  return axios.get(`/campaigns/`, { params })
}

export const createCampaign = (payload) => {
  return axios.post(`/campaigns/`, payload)
}

export const updateCampaign = (campaignId, payload) => {
  return axios.put(`/campaigns/${campaignId}`, payload)
}

export const getCampaignById = (campaignId) => {
  return axios.get(`/campaigns/${campaignId}`)
}

export const deleteCampaign = (campaignId) => {
  return axios.delete(`/campaigns/${campaignId}`)
}

export const updateCampaignStatus = (campaignId, payload) => {
  return axios.put(`/campaigns/${campaignId}/status`, payload)
}

export const uploadAcceptanceImages = (campaignId, payload) => {
  return axios.post(`/campaigns/${campaignId}/acceptanceImages`, payload)
}

export const finishUploadAcceptanceImages = (campaignId, acceptanceImagesId) => {
  return axios.post(`/campaigns/${campaignId}/acceptanceImages/${acceptanceImagesId}`)
}

export const deleteAcceptanceImages = (campaignId, acceptanceImagesId) => {
  return axios.delete(`/campaigns/${campaignId}/acceptanceImages/${acceptanceImagesId}`)
}

export const updateProductPerformance = (campaignId, productId, payload) => {
  return axios.put(`/campaigns/${campaignId}/products/${productId}/performance`, payload)
}

export const updateCampaignPrintingContentStatus = (campaignId, payload) => {
  return axios.patch(`/campaigns/${campaignId}/printingContent`, payload)
}

export const getBillingSummary = (campaignId) => {
  return axios.post(`/campaigns/${campaignId}/billingSummary`)
}
